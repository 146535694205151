<template>
  <div
    class="v-application v-application--is-ltr theme--light menuable__content__active"
  >
    <b-row class="mb-4">
      <b-col lg="4" class="my-1">
        <b-form-group class="mb-0" v-slot="{ ariaDescribedby }">
          <b-input-group size="sm">
            <b-form-select
              id="sort-by-select"
              v-model="sortBy"
              :options="sortOptions"
              :aria-describedby="ariaDescribedby"
              class="w-75"
            >
              <template #first>
                <option value="">-- Sort by --</option>
              </template>
            </b-form-select>

            <b-form-select
              v-model="sortDesc"
              :disabled="!sortBy"
              :aria-describedby="ariaDescribedby"
              size="sm"
              class="w-25"
            >
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <!--begin::Brodcast-->
    <div class="row">
      <div class="col-xxl-12 col-12 col-md-12">
        <b-card>
          <b-button variant="primary gutter-b" @click="handleButtonAdd()"
            >Tambah</b-button
          >
          <div class=""></div>
          <div class="table-responsive">
            <b-table
              hover
              :items="tableData"
              :fields="fields"
              :per-page="params.limit"
              :current-page="refCurrentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              show-empty
            >
              <template #cell(action)="row">
                <span class="mr-2" @click="handleView(row.item)">
                  <b-button
                    variant="primary"
                    class="py-1 px-2"
                    id="detail"
                    title="detail"
                  >
                    <i class="menu-icon flaticon-eye pr-0"></i>
                  </b-button>
                </span>
                <span
                  class="mr-2"
                  @click="handleEdit(row.item)"
                  v-if="
                    permission.some(
                      (item) => item.name === 'can_view_presence_report'
                    )
                  "
                >
                  <b-button
                    variant="warning"
                    class="py-1 px-2"
                    id="edit"
                    title="edit"
                  >
                    <i class="menu-icon flaticon-edit pr-0"></i>
                  </b-button>
                </span>
                <!-- <span @click="handleDelete(row.item)">
                  <b-button
                    variant="danger"
                    class="py-1 px-2"
                    id="delete"
                    title="delete"
                  >
                    <i class="menu-icon flaticon-delete pr-0"></i>
                  </b-button>
                </span> -->
              </template>
            </b-table>
          </div>
          <div class="row justify-content-between">
            <div class="col-md-1">
              <b-form-group class="mb-0">
                <b-form-select
                  id="per-page-select"
                  v-model="params.limit"
                  :options="pageOptions"
                  size="sm"
                ></b-form-select>
              </b-form-group>
            </div>
            <div class="col-md-3">
              <b-pagination
                v-model="refCurrentPage"
                :total-rows="totalRows"
                :per-page="params.limit"
                size="md"
                align="fill"
                class="ml-auto"
                style="padding: 0"
              ></b-pagination>
            </div>
          </div>
        </b-card>
      </div>
    </div>
    <!--end::Brodcast-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import { debounce } from "debounce";

export default {
  name: "ListAdmin",
  data() {
    return {
      selected: null,
      isLoading: false,
      rowStyleOption: {
        stripe: true,
      },
      search: "",
      pageIndex: 1,
      totalRows: 10,
      currentPage: 1,
      pageSize: 10,
      perPage: 10,
      pageOptions: [10, 15, { value: 100, text: "Show a lot" }],
      sortOption: {
        sortChange: (params) => {
          this.sortChange(params);
        },
      },
      fields: [
        {
          key: "name",
          label: "Nama",
          align: "left",
          sortBy: "",
          sortByFormatted: true,
          filterByFormatted: true,
          sortable: true,
        },
        {
          key: "email",
          label: "Email",
          align: "left",
          sortBy: "",
          sortable: true,
        },
        {
          key: "action",
          label: "Action",
          align: "center",
          width: "10%",
        },
      ],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      tableData: [],
      params: {
        query: "",
        sort: "created_at",
        order: "desc",
        limit: 10,
        offset: 0,
      },
    };
  },
  components: {},
  computed: {
    ...mapGetters(["permission"]),
    refCurrentPage: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.params.offset = (value - 1) * this.params.limit;
      },
    },
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    this.getData();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Admin" },
    ]);
  },
  methods: {
    sortChange(params) {
      Object.entries(params).forEach((item) => {
        if (item[1]) {
          // eslint-disable-next-line
          console.log(item[0], item[1]);
        }
      });
    },
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
    },
    handleView(item) {
      this.$router.push({ path: `/admin/view/${item.id}` });
    },
    handleButtonAdd() {
      this.$router.push({ path: `/admin/create` });
    },
    handleEdit(item) {
      this.$router.push({ path: `/admin/edit/${item.id}` });
    },
    handleDelete(item) {
      this.$bvModal
        .msgBoxConfirm(`Please confirm that you want to delete this admin.`, {
          title: "Confirm Delete",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Delete",
          cancelTitle: "Cancel",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((trigger) => {
          if (trigger) {
            this.$axios
              .delete(
                `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/admin/${item.id}`
              )
              .then(() => {
                this.getData();
              });
          }
        });
    },
    getData() {
      try {
        const params = {
          ...this.params,
        };
        this.$axios
          .get(`${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/admin/list`, {
            params,
          })
          .then(({ data }) => {
            this.tableData = data.list;
            this.totalRows = data.total;
          });
      } catch ({ response }) {
        this.$bvToast.toast(response.data.message ?? "Terjadi Kesalahan", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    processChange: debounce(function () {
      this.getData();
    }, 500),
  },
  watch: {
    params: {
      handler() {
        this.processChange();
      },
      deep: true,
    },
    selected: {
      handler() {
        this.processChange();
      },
      deep: true,
    },
  },
};
</script>
